import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import VideoContainer from './general/video';
import { setData } from '../utils/storage';
import Cards from './cards';

const ActionPlanCards = (props) => {

  const [stepData, setStepData] = useState(props.cardData);

  const telephoneClick = () => {
    setData('telephoneKey', stepData.phoneScriptTemplateKey)
    window.gtag('event', 'use_telephone_support', {
      'label': 'Use Telephone Support',
      'screen_name': 'Action Plan',
      'value': 'Use Telephone Support'
    });
  }


  const generatedLetterClick = () => {
    setData('generatedKey', stepData.letterBuilderTemplateKey)
    window.gtag('event', 'use_letter_builder', {
      'label': 'Use Letter Builder',
      'screen_name': 'Action Plan',
      'value': 'Use Letter Builder'
    });
  }

  const downloadLetterTemplate = () => {
    window.gtag('event', 'download_letter_Template', {
      'label': 'Download Letter Template',
      'screen_name': 'Action Plan',
      'value': 'Download Letter Template'
    });
  }

  return (
    <div className={props.greenBorder ? 'greenBorder action-cards' : 'action-cards'}>
      <div>
        {stepData.stepTitle !== "" ? (
          <h3 className='text-24'>{stepData.stepTitle}</h3>
        ) : null}
        {stepData.stepBody !== "" ? (
          <p className='text-17 mb-0' dangerouslySetInnerHTML={{ __html: stepData.stepBody }} />
        ) : null}
        {stepData.stepSubHeading !== "" ? (
          <h4 className='text-18'>{stepData.stepSubHeading}</h4>
        ) : null}
        {stepData.stepSubBody !== "" ? (
          <p className='text-17 mb-0' dangerouslySetInnerHTML={{ __html: stepData.stepSubBody }} />
        ) : null}
        {stepData.embedCode ? (
          <VideoContainer code={stepData.embedCode} />

        ) : null}
        {Array.isArray(stepData.externalSites) && stepData.externalSites.length > 0 ? (
          <div>
            <p className='text-17 mb-0' style={{ marginBottom: '0 !important' }}>{'Find out more information here:'}</p>
            <ul className='card-container action-plan-external d-flex mt-0'>
              {stepData.externalSites.map((item) => {
                return <Cards type='site' title={item.externalSiteTitle} link={item.externalSiteLink} />
              })}
            </ul>
          </div>
        ) : null}
        <div className='d-flex justify-content-start flex-column flex-md-row align-items-center'>
          {stepData.phoneScriptEnabled && stepData.phoneScriptTemplateKey !== '' ? (
            <>
              <Link to="/get-advice/telephone-support" state={{ telephoneKey: stepData.phoneScriptTemplateKey }} onClick={() => telephoneClick()} className='button without-arrow text-center'>Use telephone support</Link>
            </>
          ) : null}
          {stepData.letterBuilderEnabled && stepData.letterBuilderTemplateKey !== '' ? (
            <>
              {stepData.phoneScriptEnabled && stepData.phoneScriptTemplateKey !== '' ? (
                <span className='mx-2 d-none d-md-block button-option'>or</span>
              ) : null}
              <Link to="/get-advice/letter-builder" state={{ letterBuilderKey: stepData.letterBuilderTemplateKey }} onClick={() => generatedLetterClick()} className='button without-arrow text-center'>Use letter builder</Link>

            </>
          ) : null}


          {stepData.letterTemplateEnabled ? (
            <>
              {stepData.phoneScriptEnabled && stepData.phoneScriptTemplateKey !== '' || stepData.letterBuilderEnabled && stepData.letterBuilderTemplateKey !== '' ? (
                <span className='mx-2 d-none d-md-block button-option'>or</span>
              ) : null}
              <a href={stepData.letterTemplate} target='_blank' className='button without-arrow text-center' onClick={() => downloadLetterTemplate()}>Download the letter template</a>
            </>
          ) : null}

        </div>


      </div>
    </div>
  )
}

export default ActionPlanCards
