import React, { useEffect, useState } from "react";
import {
	AccordionContainer,
	BlogContainer,
	Cards,
	DropdownContainer,
	GetHelpSection,
	Loader,
	TopicsList,
} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopicsData, resetTopicsData } from "./store/actions";
import { Helmet } from "react-helmet";

const Topics = () => {
	const [mainContent, setMainContent] = useState();
	const [topicsData, setTopicsData] = useState("");
	const [topicName, setTopicName] = useState();
	const [topicList, setTopicList] = useState([]);
	const [subTopicList, setSubTopicList] = useState([]);
	const [filterVal, setFilterVal] = useState([]);

	const location = useParams();
	const navigate = useNavigate();
	const subTopicParam = location.subtopics;
	const filterParam = location.filter;
	const [dropdownVal, setDropDownVal] = useState("");
	const [subTopicSlug, setSubTopicSlug] = useState("");
	const [externalSites, setExternalSites] = useState([]);

	const [filteredData, setFilteredData] = useState("");
	const topicListContent = useSelector((state) => state.topicsDataList);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchTopicsData());
	}, []);

	useEffect(() => {
		if (topicListContent.isSuccess && topicListContent.data !== null) {
			setMainContent(topicListContent.data);
			dispatch(resetTopicsData());
		} else if (topicListContent.isError) {
			dispatch(resetTopicsData());
		}
	}, [topicListContent]);

	useEffect(() => {
		if (
			topicListContent.isSuccess &&
			mainContent !== undefined &&
			!Array.isArray(mainContent?.success.data)
		) {
			setTopicsData(mainContent?.success.data);
			let topicFullData = mainContent?.success.data;
			let topicData = Object.keys(topicFullData);

			if (
				topicData.includes(subTopicParam) &&
				topicFullData[subTopicParam] !== undefined &&
				topicFullData[subTopicParam].subTopics.length > 0
			) {
				if (subTopicParam && filterParam) {
					setSubTopicSlug(filterParam);

					for (const list in topicFullData) {
						let subTopicData = topicFullData[list].subTopics;

						if (Object.keys(subTopicData).length > 0) {
							let obj = {
								title: topicFullData[list].topicTitle,
								slug: topicFullData[list].topicSlug,
							};
							let objCount = topicList.filter(
								(item) => item.title === obj.title
							);
							if (!objCount.length > 0) {
								setTopicList((current) => [...current, obj]);
							}

							if (list === subTopicParam) {
								setSubTopicList(subTopicData);
								setTopicName(topicFullData[list].topicTitle);
								setDropDownVal(subTopicData[filterParam].subTopicTitle);
								setFilteredData(subTopicData[filterParam]);
							}
						}
					}

					navigate(`/topics/${subTopicParam}/${filterParam}`);
				} else if (subTopicParam && !filterParam) {
					console.log("dataaaa1");
					for (const list in topicFullData) {
						let obj = {
							title: topicFullData[list].topicTitle,
							slug: list,
						};

						let subTopicData = topicFullData[list].subTopics;

						if (Object.keys(subTopicData).length > 0) {
							let objCount = topicList.filter(
								(item) => item.title === obj.title
							);
							if (!objCount.length > 0) {
								setTopicList((current) => [...current, obj]);
							}
							if (list === subTopicParam) {
								setSubTopicList(subTopicData);
								setTopicName(topicFullData[list].topicTitle);
								setDropDownVal(Object.keys(subTopicData)[0].subTopicTitle);
								setSubTopicSlug(Object.keys(subTopicData)[0]);
								setFilteredData(Object.values(subTopicData)[0]);
								navigate(
									`/topics/${subTopicParam}/${Object.keys(subTopicData)[0]}`
								);
							}
						}
					}
				} else {
					for (const list in topicFullData) {
						let obj = {
							title: topicFullData[list]?.topicTitle,
							slug: topicFullData[list]?.topicSlug,
						};

						let subTopicData = topicFullData[list]?.subTopics;
						if (Object.keys(subTopicData).length > 0) {
							let objCount = topicList.filter(
								(item) => item.title === obj.title
							);
							if (!objCount.length > 0) {
								setTopicList((current) => [...current, obj]);
							}
							if (list === subTopicParam) {
								setSubTopicList(subTopicData);
								setTopicName(subTopicParam);
							}
						}
					}

					let firstElement = Object.values(topicFullData)[0];

					setTopicName(firstElement?.topicTitle);
					setSubTopicList(firstElement?.subTopics);
					setSubTopicSlug(
						Object.keys(firstElement?.subTopics)[0].subTopicTitle
					);
					setFilteredData(Object.values(firstElement?.subTopics)[0]);
					navigate(
						`/topics/${Object.keys(topicFullData)[0]}/${
							Object.keys(firstElement?.subTopics)[0]
						}`
					);
				}
			} else {
				for (const list in topicFullData) {
					let obj = {
						title: topicFullData[list]?.topicTitle,
						slug: topicFullData[list]?.topicSlug,
					};

					let subTopicData = topicFullData[list]?.subTopics;
					if (Object.keys(subTopicData).length > 0) {
						let objCount = topicList.filter((item) => item.title === obj.title);
						if (!objCount.length > 0) {
							setTopicList((current) => [...current, obj]);
						}
						if (list === subTopicParam) {
							setSubTopicList(subTopicData);
							setTopicName(subTopicParam);
						}
					}
				}

				let firstElement = Object.values(topicFullData)[0];

				setTopicName(firstElement?.topicTitle);
				setSubTopicList(firstElement?.subTopics);
				setSubTopicSlug(Object.keys(firstElement?.subTopics)[0].subTopicTitle);
				setFilteredData(Object.values(firstElement?.subTopics)[0]);
				navigate(
					`/topics/${Object.keys(topicFullData)[0]}/${
						Object.keys(firstElement?.subTopics)[0]
					}`
				);
			}
		}
	}, [mainContent]);

	const onTopicChange = (topic, slug) => {
		setTopicName(topic);
		setFilterVal([]);
		for (const listData in topicsData) {
			if (slug === listData) {
				let subTopicData = topicsData[listData].subTopics;
				setSubTopicList(subTopicData);
				setDropDownVal(Object.values(subTopicData)[0].subTopicTitle);
				setSubTopicSlug(Object.keys(subTopicData)[0]);
				setFilteredData(Object.values(subTopicData)[0]);
				navigate(`/topics/${slug}/${Object.keys(subTopicData)[0]}`);
			}
		}
	};

	useEffect(() => {
		for (const filterVal in subTopicList) {
			let obj = {
				id: filterVal,
				value: subTopicList[filterVal].subTopicTitle,
			};
			setFilterVal((current) => [...current, obj]);
		}
	}, [subTopicList]);

	const onDropDownClick = (val) => {
		setDropDownVal(val);
		for (let subTopicSlug in subTopicList) {
			if (subTopicList[subTopicSlug].subTopicTitle === val) {
				setFilteredData(subTopicList[subTopicSlug]);
				navigate(`/topics/${subTopicParam}/${subTopicSlug}`);
			}
		}
	};

	useEffect(() => {
		console.log(topicName, "filter");
	}, [filteredData]);

	return (
		<div>
			<Helmet>
				<title>{`${
					topicName ? topicName + " | " : ""
				}Topics | My Housing Gateway`}</title>
				<meta
					property="og:title"
					content={`${
						topicName ? topicName + " | " : ""
					}Topics | My Housing Gateway`}
				/>
				<meta
					name="twitter:card"
					content={`${
						topicName ? topicName + " | " : ""
					}Topics | My Housing Gateway`}
				/>
				<meta description="If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue." />
			</Helmet>
			{topicListContent.isFetching ? (
				<Loader message={"Loading....."} />
			) : topicListContent.isSuccess &&
			  mainContent !== undefined &&
			  !Array.isArray(mainContent?.success.data) ? (
				<div className="topics">
					<TopicsList
						topicListData={topicList}
						title={topicName}
						onTopicChange={onTopicChange}
						filterSlug={subTopicSlug}
					/>
					<div>
						<div className="bg-white py-3 py-md-5 topic-blog-container">
							<div className="container">
								<h2 className="topic-title textBlue text-title-24">
									{topicName}
								</h2>
								<DropdownContainer
									title={
										"I wish to read information on this topic relating to the"
									}
									color={"lightBlue"}
									dropdownvalue={dropdownVal}
									options={filterVal}
									onDropDownChange={onDropDownClick}
								/>
							</div>
							<div className="container">
								{Array.isArray(filteredData.contentBlocks) &&
								filteredData.contentBlocks ? (
									<div>
										{filteredData.contentBlocks.map((blogItem) => {
											return <BlogContainer blogItem={blogItem} />;
										})}
									</div>
								) : null}
							</div>
						</div>
						{Array.isArray(filteredData.caseStudies) &&
						filteredData.caseStudies.length ? (
							<div className="bg-cream case-card">
								<div className="container">
									{filteredData.caseStudies ? (
										<h3 className="text-title-26">
											Case studies from private tenants
										</h3>
									) : null}
									{filteredData.caseStudies ? (
										<ul className="card-container d-flex flex-column flex-wrap flex-md-row justify-content-between case-list-container">
											{filteredData.caseStudies.map((caseItem) => {
												return (
													<Cards
														type="case"
														title={`${caseItem.caseStudyName} , ${caseItem.caseStudyLocation}`}
														description={caseItem.caseStudyTestimonial}
													/>
												);
											})}
										</ul>
									) : null}
								</div>
							</div>
						) : null}
						{Array.isArray(filteredData.faqs) && filteredData.faqs.length ? (
							<div className="bg-white py-4 py-md-5 topic-faq">
								<div className="container">
									{filteredData.faqs ? (
										<div>
											<h3 className="text-title-28 faq-title">
												Frequently asked questions
											</h3>
											<AccordionContainer list={filteredData.faqs} />
										</div>
									) : null}
								</div>
							</div>
						) : null}
						{filteredData.externalSites &&
						mainContent !== undefined &&
						filteredData.externalSites.length > 0 ? (
							<div>
								<GetHelpSection
									title="Where can I get further information?"
									description="The above information provides general advice about Landlord’s responsibilities in relation to repairs and maintenance and has been produced by the TDS Charitable Foundation with support from JMW Solicitors."
									list={filteredData.externalSites}
								/>
							</div>
						) : null}
					</div>
				</div>
			) : topicListContent.isSuccess &&
			  Array.isArray(mainContent?.success.data) ? (
				<div className="container">
					<p className="text-24 mt-4">No data found</p>
				</div>
			) : null}
		</div>
	);
};

export default Topics;
